import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Box, Tooltip } from '@mui/material';
import { reusableStyles } from 'constants/style';
import { jobsAnalytics } from 'features/jobs/utils/analytics';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { shouldShowAnnouncement } from 'utils/announcement';
import storage from 'utils/storage';

const JOBS_ICON_FLASHING_ANNOUCEMENT_ID = 'jobs-icon-flashing';

const JobsMenu = () => {
  const { showNotificationIndicator } = useAppSelector((state) => state.jobs.jobsShared);
  const { pathname } = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    storage.setAnnouncementFirstShown(JOBS_ICON_FLASHING_ANNOUCEMENT_ID);
  }, []);

  const handleJobsMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (pathname === '/jobs') return;
    jobsAnalytics.featuredAccessed({
      accessed_from_page: window.location.href,
      source: 'navigation menu'
    });
    storage.setAnnouncementClicked(JOBS_ICON_FLASHING_ANNOUCEMENT_ID);
    navigation('jobs');
  };

  return (
    <>
      <Tooltip title="View Jobs">
        <Box
          sx={{
            animation: shouldShowAnnouncement(JOBS_ICON_FLASHING_ANNOUCEMENT_ID)
              ? 'blink 1.5s infinite'
              : undefined,
            '&:hover': { cursor: 'pointer' },
            position: 'relative',
            width: '40px',
            height: '40px',
            border: '1px solid rgba(221, 255, 85, 0.08)',
            borderRadius: 99,
            backgroundColor: 'rgba(221, 255, 85, 0.08)',
            ...reusableStyles?.centerContent
          }}
          id="jobs-menu"
          onClick={handleJobsMenu}>
          <PlaylistAddCheckIcon
            sx={{
              '&.MuiSvgIcon-root': {
                color: pathname === '/jobs' ? '#DF5' : '#fff'
              }
            }}
          />
          {showNotificationIndicator && (
            <Box
              sx={{
                aspectRatio: '1',
                width: '12px',
                border: '2px solid black',
                backgroundColor: '#E15D5D',
                borderRadius: '50%',
                position: 'absolute',
                top: '0',
                right: '0'
              }}
            />
          )}
        </Box>
      </Tooltip>
    </>
  );
};

export default JobsMenu;
