import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cleanUpTextInfo } from '../../../constant';
import { setBulkOnboardSchedulePayload } from '../../../store';
import InfoBox from '../../infoBox';

const CleanupOptions: FC = () => {
  const dispatch = useAppDispatch();
  const { bulkOnboardSchedulePayload, scheduleModalPropsList } = useAppSelector(
    (state) => state.unmanagedVm
  );
  const [dateFilterType, setDateFilterType] = useState<string>('3days');
  const [infoText, setInfoText] = useState<string>(cleanUpTextInfo);

  useEffect(() => {
    let cleanUpDaysText = '3days';
    const cleanUpDays = scheduleModalPropsList?.cleanUpDaysFromOnboardDate;
    scheduleModalPropsList;
    if (cleanUpDays) {
      if (cleanUpDays === 1) {
        cleanUpDaysText = '1day';
      } else if (cleanUpDays === null) {
        cleanUpDaysText = 'dontDelete';
      } else {
        cleanUpDaysText = `${cleanUpDays}days`;
      }
    } else if (scheduleModalPropsList?.onboardUpdate) {
      cleanUpDaysText = 'dontDelete';
    }
    setDateFilterType(cleanUpDaysText);
    updateCleanupDaysInPayload(cleanUpDaysText);
  }, [scheduleModalPropsList]);

  const isDaysSelected = (text: string) => text !== 'dontDelete';

  const updateCleanupDaysInPayload = (selectedDay: string) => {
    const numberFromSelectedDays = isDaysSelected(selectedDay)
      ? getNumberForDays(selectedDay)
      : null;
    dispatch(
      setBulkOnboardSchedulePayload({
        supportingScheduleInfo: {
          ...bulkOnboardSchedulePayload?.supportingScheduleInfo,
          cleanupDelayDays: numberFromSelectedDays
        }
      })
    );
  };

  const getNumberForDays = (days: string) =>
    days === '10days' ? Number(days?.substring(0, 2)) : Number(days?.[0]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDay = (event.target as HTMLInputElement).value;
    const numberFromSelectedDays = getNumberForDays(selectedDay);
    setDateFilterType(selectedDay);

    updateCleanupDaysInPayload(selectedDay);

    if (isDaysSelected(selectedDay)) {
      setInfoText(`
      Original volume will be deleted after the onboarding has completed. 
For Eg. if the Onboarding completes on Aug 3 then volume will be deleted on Aug ${
        3 + numberFromSelectedDays
      }`);
    }
  };

  return (
    <>
      <FormControl
        sx={{
          mt: 1,
          '.MuiFormLabel-root': {
            '&.Mui-focused': {
              color: 'white'
            }
          }
        }}>
        <FormLabel
          sx={{
            mb: 1.5
          }}>
          Delete Original Disk Volume After
        </FormLabel>
        <RadioGroup
          row
          name="controlled-radio-buttons-group"
          value={dateFilterType}
          onChange={handleChange}>
          <FormControlLabel value="1day" control={<Radio />} label="1day" />
          <FormControlLabel value="3days" control={<Radio />} label="3days" />
          <FormControlLabel value="7days" control={<Radio />} label="7days" />
          <FormControlLabel value="10days" control={<Radio />} label="10days" />
          <FormControlLabel value="dontDelete" control={<Radio />} label="Don't Delete" />
        </RadioGroup>
      </FormControl>
      {isDaysSelected(dateFilterType) && <InfoBox content={infoText} showIcon={false} />}
    </>
  );
};

export default CleanupOptions;
