import { Box, Divider, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Buttons from 'components/buttons';
import DatePickers from 'components/dateTimePicker/DatePickers';
import TimePickers from 'components/dateTimePicker/TimePickers';
import { add, differenceInMinutes, format, sub, isToday, addMinutes } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setBulkOnboardSchedulePayload, setModalPreference } from '../../store';
import InfoBox, { InfoBoxStyle } from '../infoBox';
import CleanupOptions from './cleanUpOptions';
import Star from '../../assets/stars.svg';
import {
  getMinDateForSchedule,
  isSetMinDate,
  getLinuxNonRootInfoMsg
} from '../../utils/helper';
import { msgContent } from '../../constant';
import DiskTypeCheckBox from '../diskType/DiskTypeCheckBox';
import { ScheduleInstanceInfo } from '../../types';
import RebootTimeRange from './rebootTimeRange';

interface ScheduleOnboardModalType {
  handleOnboard?: (e: any) => void;
  handleModalClose: (e: any) => void;
  hideProgress?: boolean;
}

const REBOOT_DURATION = 30;
const ScheduleOnboardModal: FC<ScheduleOnboardModalType> = (props) => {
  const dispatch = useAppDispatch();
  const { handleOnboard, handleModalClose, hideProgress = false } = props;
  const { bulkOnboardSchedulePayload, bulkSchedule, scheduleModalPropsList } =
    useAppSelector((state) => state.unmanagedVm);
  const [dates, setDate] = useState<any>(null);
  const [times, setTime] = useState<any>(null);
  const [setMinTime, useSetMinTime] = useState<any>(undefined);
  const [enableButton, setEnableButton] = useState<any>(false);
  const [msg, setMsg] = useState<string>('');
  const [msgList, setMsgList] = useState<string[]>([]);
  const [rebootRangeErr, setRebootRangeErr] = useState<string>('');
  const [rebootRangeInMinutes, setRebootRangeInMinutes] = useState(0);

  useEffect(() => {
    const dateAndTime = bulkOnboardSchedulePayload?.scheduledStartTime
      ? bulkOnboardSchedulePayload?.scheduledStartTime
      : bulkOnboardSchedulePayload?.rebootStartTime;
    if (dateAndTime) {
      handleDate(new Date(dateAndTime));
      handleTime(new Date(dateAndTime));
    }
  }, []);

  const handleDate = (e: any) => {
    setMsg('');
    const selectedDate = new Date(e);
    const tomorrow = add(new Date(), { days: 1 });
    tomorrow.setHours(0, 0, 0, 0);
    const d = format(selectedDate, 'yyyy-MM-dd');
    setDate(d);

    if (selectedDate >= tomorrow) {
      useSetMinTime(undefined);
    } else {
      useSetMinTime(
        isSetMinDate(e, scheduleModalPropsList)
          ? getMinDateForSchedule(scheduleModalPropsList)
          : new Date()
      );
    }
  };

  const handleTime = (e: any) => {
    setMsg('');
    const t = format(e, 'HH:mm:ss');
    setTime(t);
  };

  const getLinuxMessage = (onboardDate: string, onboardTime: string) => {
    return msgContent.rebootMsg[3]
      .replace('{onboardDate}', onboardDate)
      .replace('{onboardTime}', onboardTime);
  };

  const getWindowsMessage = (onboardDate: string, onboardTime: string) => {
    if (scheduleModalPropsList?.onboardType == 'root') {
      return msgContent.rebootMsg[1]
        .replace('{onboardDate}', onboardDate)
        .replace('{onboardTime}', onboardTime);
    } else {
      return msgContent.rebootMsg[1].replace('{onboardTime}', onboardTime);
    }
  };

  const formatMessage = (startEndTime: any, onboardDate: string, onboardTime: string) => {
    return [
      msgContent.rebootMsg[0]
        .replace('{startTime}', startEndTime.startTime)
        .replace('{endTime}', startEndTime.endTime),
      ...(bulkOnboardSchedulePayload?.osType === 'Windows'
        ? [getWindowsMessage(onboardDate, onboardTime)]
        : []),
      ...(bulkOnboardSchedulePayload?.osType === 'Linux'
        ? [getLinuxMessage(onboardDate, onboardTime)]
        : [])
    ];
  };

  const checkIsUpateScreen = () => {
    return scheduleModalPropsList?.onboardUpdate
  };

  const onboardContainAzureInstance = () => {
    return scheduleModalPropsList?.cloudProviderList.includes('AZURE');
  };

  const getWinOnboardDateAndTime = (selectedTime: Date) => {
    const onboardDate = sub(selectedTime, { minutes: REBOOT_DURATION });
    const onboardTime = format(sub(selectedTime, { minutes: REBOOT_DURATION }), 'HH:mm');
    return { onboardDate: onboardDate, onboardTime: onboardTime };
  };

  const handleBack = () => {
    dispatch(setModalPreference('confirmOnboard'));
  }

  const windowsNoneRootValidation = (
    diffInMinute: number,
    selectedTime: Date,
    startEndTime: any
  ) => {
    if (scheduleModalPropsList?.onboardTimeGapInMinutes) {
      if (diffInMinute < scheduleModalPropsList?.onboardTimeGapInMinutes) {
        setMsg(
          msgContent.rebootMsg[2].replace(
            '{timeGap}',
            String(scheduleModalPropsList?.onboardTimeGapInMinutes)
          )
        );
        setMsgList([]);
        setEnableButton(false);
      } else {
        const { onboardDate, onboardTime } = getWinOnboardDateAndTime(selectedTime);
        setMsgList(formatMessage(startEndTime, format(onboardDate, 'MMM d, yyyy'), onboardTime));
        setMsg('');
        setEnableButton(true);
      }
    }
  };

  const linuxNonRootValidation = (selectedTime: Date) => {
    if (!scheduleModalPropsList?.scheduleInstanceInfo) return

    if (scheduleModalPropsList?.onboardType === 'root') {
      setMsg(msgContent.rootLinuxAndWindows);
      setMsgList([]);
    } else {
      const rebootWindowInHours = rebootRangeInMinutes / 60
      const infoMsg = getLinuxNonRootInfoMsg(scheduleModalPropsList.scheduleInstanceInfo, selectedTime, rebootWindowInHours)
      setMsgList(infoMsg);
      setMsg('');
    }
    setEnableButton(true);
  };

  useEffect(() => {
    if (dates && times) {
      const currentTime = new Date();
      const selectedTime = new Date(dates + ' ' + times);

      const startEndTime = { startTime: '', endTime: '' };
      const diffInMinute = differenceInMinutes(selectedTime, currentTime);
      startEndTime.startTime = format(selectedTime, 'HH:mm');
      startEndTime.endTime = format(add(selectedTime, { minutes: REBOOT_DURATION }), 'HH:mm');
      startEndTime.endTime = format(
        add(selectedTime, {
          minutes:
            bulkOnboardSchedulePayload?.osType !== 'Linux' ? REBOOT_DURATION : rebootRangeInMinutes
        }),
        'HH:mm'
      );
      const rebootEndTime = addMinutes(selectedTime, rebootRangeInMinutes).toISOString();

      dispatch(
        setBulkOnboardSchedulePayload({
          ...(bulkOnboardSchedulePayload?.supportingScheduleInfo?.rebootRequired
            ? { rebootStartTime: selectedTime.toISOString() }
            : { scheduledStartTime: selectedTime.toISOString() }),
            rebootEndTime
        })
      );

      if (
        selectedTime < currentTime ||
        selectedTime < getMinDateForSchedule(scheduleModalPropsList)
      ) {
        setEnableButton(false);
        setMsg(msgContent?.common?.pastTimeError);
        return;
      }

      if (
        bulkOnboardSchedulePayload?.osType === 'Windows' &&
        !scheduleModalPropsList?.restartMessageDisabled &&
        bulkOnboardSchedulePayload?.supportingScheduleInfo?.rebootRequired
      ) {
        windowsNoneRootValidation(diffInMinute, selectedTime, startEndTime);
      } else if (
        bulkOnboardSchedulePayload?.osType === 'Windows' &&
        bulkOnboardSchedulePayload?.supportingScheduleInfo?.rebootRequired === false &&
        scheduleModalPropsList?.onboardType === 'root'
      ) {
        setMsg(msgContent.rootLinuxAndWindows);
        setMsgList([]);
        setEnableButton(true);
      } else if (
        bulkOnboardSchedulePayload?.osType === 'Linux' &&
        !scheduleModalPropsList?.restartMessageDisabled
      ) {
        linuxNonRootValidation(selectedTime);
      } else {
        setEnableButton(true);
        setMsg('');
        setMsgList([]);
      }
    }
  }, [dates, times, rebootRangeInMinutes]);

  const shoudleButtonDisable = () => {
    return (bulkOnboardSchedulePayload?.scheduledStartTime ||
      bulkOnboardSchedulePayload?.rebootStartTime) &&
      !rebootRangeErr.length &&
      enableButton === true
      ? false
      : true;
  };

  const onPickerError = (e: any) => {
    if (e === null && dates && times) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  };

  const getScheduleDateTime = () => {
    if (bulkOnboardSchedulePayload?.scheduledStartTime) {
      return new Date(bulkOnboardSchedulePayload?.scheduledStartTime);
    } else if (bulkOnboardSchedulePayload?.rebootStartTime) {
      return new Date(bulkOnboardSchedulePayload?.rebootStartTime);
    } else {
      return null;
    }
  };

  const handleRebootTimeRangeChange = (minutes: number) => {
    setMsg('');
    setRebootRangeInMinutes(minutes);
  };

  const getTotalSavings = () => {
    if (scheduleModalPropsList?.isConversionCheckBoxChecked) {
      return scheduleModalPropsList?.totalSavingWithConversion;
    } else {
      return scheduleModalPropsList?.totalSaving;
    }
  };

  const allInstanceCompatibleforV2Conversion = () => {
    return scheduleModalPropsList?.scheduleInstanceInfo?.some((itm: ScheduleInstanceInfo) => {
      return itm.currentDiskRequirementsForV2IconDisplay;
    });
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={1}>
        {checkIsUpateScreen() ? 'Reschedule' : 'Onboarding'} instances
      </Typography>

      {!checkIsUpateScreen() && scheduleModalPropsList && (
        <Box
          sx={{
            ...InfoBoxStyle
          }}>
          <img src={Star} alt="Star" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <Typography
              variant="body2"
              gutterBottom
              fontSize={16}
              lineHeight="24px"
              color="primary"
              mb={0}>
              Savings with this onboarding
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              fontSize={16}
              lineHeight="24px"
              color="primary"
              mb={0}>
              {'$' + getTotalSavings()}
            </Typography>
          </Box>
        </Box>
      )}

      {!hideProgress && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          data-testid="progress-container">
          <Box sx={{ height: '3px', backgroundColor: '#ddff55', flex: 1 }} />
          <Box sx={{ height: '3px', backgroundColor: '#ddff55', flex: 1 }} />
        </Box>
      )}

      {checkIsUpateScreen() && (
        <InfoBox content="Selected instances will be rescheduled. Other instances in the batch will be onboarded as per previous scheduled date & time." />
      )}

      <Box sx={{ mt: 2 }}>
        {scheduleModalPropsList?.onboardType === 'dataVolume' &&
          bulkOnboardSchedulePayload?.osType === 'Windows' &&
          bulkOnboardSchedulePayload?.supportingScheduleInfo?.rebootRequired === false && (
            <Typography variant="body2" gutterBottom fontSize={16} lineHeight="24px" mb={1}>
              Volumes Not Requiring Reboot. Onboard selected data volumes on
            </Typography>
          )}
        {/*  2019 */}
        {scheduleModalPropsList?.restartMessageDisabled === false &&
          bulkOnboardSchedulePayload?.osType === 'Windows' &&
          scheduleModalPropsList?.onboardType !== 'root' && (
            <Typography variant="body2" gutterBottom fontSize={16} lineHeight="24px" mb={1}>
              Select reboot window for selected{' '}
              {bulkOnboardSchedulePayload?.mountPointInfo?.length || 0} data volume
            </Typography>
          )}
        {bulkOnboardSchedulePayload?.osType === 'Linux' &&
          scheduleModalPropsList?.onboardType !== 'root' &&
          scheduleModalPropsList?.restartMessageDisabled === false && (
            <>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px'
                }}
              >
                Schedule reboot required for onboarding
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  opacity: 0.8
                }}
              >
                Please ensure that the instance is powered on during this time period.
              </Typography>
            </>
          )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          '.MuiInputBase-root': {
            borderRadius: '12px',
            height: '42px'
          },
          '.MuiFormControl-root': {
            height: 50
          }
        }}>
        <Box
          sx={{
            flex: 1,
            '.MuiFormControl-root': {
              width: '100%'
            }
          }}
        >
          <Typography variant="h4" gutterBottom fontSize={16} lineHeight="20px" mb={1}>
            From
          </Typography>
          <DatePickers
            dateValue={getScheduleDateTime()}
            handleDate={handleDate}
            onPickerError={onPickerError}
            minDate={getMinDateForSchedule(scheduleModalPropsList)}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            '.MuiFormControl-root': {
              width: '100%'
            }
          }}
        >
          <Typography variant="h4" gutterBottom fontSize={16} lineHeight="20px" mb={1}>
            Time
          </Typography>
          <TimePickers
            timeValue={getScheduleDateTime()}
            handleTime={handleTime}
            onPickerError={onPickerError}
            disablePast={setMinTime ? false : isToday(new Date(dates)) ? true : false}
            minTime={setMinTime}
          />
        </Box>
        {bulkOnboardSchedulePayload?.osType === 'Linux' && scheduleModalPropsList?.onboardType !== 'root' &&
         <Box 
          sx={{
            flex: 1,
            '.MuiFormControl-root': {
              width: '100%'
            }
          }}
        >
          <Typography variant="h4" gutterBottom fontSize={16} lineHeight="20px" mb={1}>
            Reboot time range
          </Typography>
          <RebootTimeRange onError={setRebootRangeErr} onChange={handleRebootTimeRangeChange} />
        </Box>}
      </Box>
      
      {rebootRangeErr.length > 0 && (
        <Typography mt={1} fontSize={'12px'} lineHeight={'16px'} color='rgba(225, 93, 93, 1)'>
          {rebootRangeErr}
        </Typography>
      )}

      {msgList?.length > 0 && !msg && <InfoBox contentList={msgList} showIcon={false} />}
      {msg && <InfoBox content={msg} showIcon={false} />}

      <Divider sx={{ my: 3 }} />

      <CleanupOptions />

      {!checkIsUpateScreen() && onboardContainAzureInstance() && (
        <>{allInstanceCompatibleforV2Conversion() && <DiskTypeCheckBox />}</>
      )}

      <Box sx={{ display: 'flex', gap: 3, mt: 3.75, justifyContent: 'flex-end' }}>
        <Buttons
          sx={{
            color: '#ddff55'
          }}
          btnVarient="text"
          onClick={handleBack}>
          Back
        </Buttons>
        <Buttons
          disabled={shoudleButtonDisable()}
          onClick={handleOnboard}
          loading={bulkSchedule?.status === 'loading'}
          sx={{
            minWidth: '110px'
          }}>
          {checkIsUpateScreen() ? 'Reschedule' : 'Onboard'}
        </Buttons>
      </Box>
    </Box>
  );
};

export default ScheduleOnboardModal;
